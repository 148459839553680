import {
  oneTimeSetup,
  sharedSetup,
  logCustomDatadogAction,
  logDatadogError,
  attachNewBadgeForButtons,
  VanillaFittty,
  RubberBandPinchColor,
  BackButton,
  toggleHelpWidgetVisibility,
  lazyBGImageObserver,
  SetupUnifiedMobileMenu
} from "setup_helper"

export function importmapSetup() {
  oneTimeSetup()

  document.addEventListener("DOMContentLoaded", () => {
    sharedSetup()
    VanillaFittty()
    attachNewBadgeForButtons()
    RubberBandPinchColor({ updateBottomBg: true })
    SetupUnifiedMobileMenu()

    const tryProFreeButton = document.getElementById("try-pro-free")
    tryProFreeButton?.addEventListener("click", () => {
      logCustomDatadogAction?.("click_try_pro_free")
    }, { once: true })

    lazyBGImageObserver()
    if (window.App.user.id) toggleHelpWidgetVisibility()
  })

  window.App = window.App || {}
  window.App = Object.assign(window.App, {
    storedFieldPath: (resolveUrl, liveChannelId = "") => {
      if (liveChannelId === "") {
        return `/stored_fields/options?resolve_url=${resolveUrl}`
      } else {
        return `/stored_fields/options?resolve_url=${resolveUrl}&live_channel_id=${liveChannelId}`
      }
    }
  })
  window.App.Comps = {}
  window.App.Scripts = {
    BackButton
  }
  window.App.Utils = {
    logCustomDatadogAction,
    logDatadogError
  }
}
